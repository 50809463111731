import React, {useEffect, useRef, useState} from 'react';


const SomeShared = (props) =>
{
  return (
    <>
      <h1>Sharing is caring!</h1>
    </>
  );
};

export {SomeShared};
