import {SomeShared} from '@alice/shared';


function App() {
  return (
    <div className="App">
      <SomeShared />
    </div>
  );
}

export default App;
